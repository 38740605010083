import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import Container from '../components/Layout/container';
import { MainSection100 } from '../components/Layout/sections';
import NewLayout from '../components/new_layout';
import CardPost from '../components/v2023/UI/Card/card-post';
import { SocialMediaTecalis } from '../components/v2024/social-media';

const ThankYouSubscribe = ({ data, pageContext }) => {
	const page = data.page;

	return (
		<NewLayout pageContext={pageContext} noHeader thinFooter>
			<div className="thankyou">
				<MainSection100 sectionType="color" sectionColor="gray">
					<Container section="100" className="patata">
						<GatsbyImage
							alt={page.logo_tecalis.alternativeText}
							image={getImage(page.logo_tecalis.localFile)}
						/>
						<div className="container__text">
							<GatsbyImage alt={page.hero.image.alternativeText} image={getImage(page.hero.image.localFile)} />
							<div className="container__text__text">
								<h1 className='merriweather'>{page.hero.title}</h1>
							</div>
						</div>
					</Container>
				</MainSection100>
				<MainSection100>
					<Container>
						<h2>{page.interests_title}</h2>
						<div className="grid-lg-3-h">
							{page.interests_sections.map((section, index) => {
								const animation = { "data-aos": "zoom-in", "data-aos-delay": 200 * index }
								return <CardPost image={section.image} name={section.title} tag={section.tag[0].name} tagClass={`tag--${section.tag[0].type}`} url={section.url} animation={animation}/>;
							})}
						</div>
					</Container>
				</MainSection100>
				<MainSection100>
					<Container>
						<SocialMediaTecalis secondary title={page.social_media_title}/>
					</Container>
				</MainSection100>
			</div>
		</NewLayout>
	);
};

export default ThankYouSubscribe;

export const agradecimientosSuscripcionsQuery = graphql`
	query ($id: String!, $langKey: String!) {
		page: strapiAgradecimientosSuscripcions(locale: { eq: $langKey }, id: { eq: $id }) {
			logo_tecalis {
				name
				alternativeText
				localFile {
					childImageSharp {
						gatsbyImageData(width: 147, placeholder: BLURRED, formats: [WEBP])
					}
				}
			}
			hero {
				title
				image {
					name
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(width: 164, placeholder: BLURRED, formats: [WEBP])
						}
					}
				}
			}
			interests_title
			interests_sections {
				title
				tag {
					name
					type
				}
				image {
					name
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(width: 432, placeholder: BLURRED, formats: [WEBP])
						}
					}
				}
				url
			}
			social_media_title
		}
	}
`;
